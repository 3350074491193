import ReactJson from '@microlink/react-json-view';
import { Container, Grid, TextField, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { useViewLogPage } from '@/views/logs/ViewLogPage/useViewLogPage';

import { styles } from './styles';


export const ViewLogPage = () => {

  const { data } = useViewLogPage();

  const isStackJson = useMemo(() => {
    try {
      JSON.parse(data?.stack);
    } catch {
      return false;
    }

    return true;
  }, [data?.stack]);

  const stack = useMemo(() => {
    if (isStackJson) {
      return JSON.parse(data?.stack);
    }

    return data?.stack;
  }, [data])

  return (
    <Container>
      <Typography variant="h4" my={2}>
        Log #{data?.id}
      </Typography>
      <Grid container spacing={2} sx={styles.container}>
        <Grid item xs>
          <Typography variant="h6" mb={1}>
            Type
          </Typography>
          <TextField
            fullWidth
            size="small"
            value={data?.type}
            disabled
          />
        </Grid>
        <Grid item xs>
          <Typography variant="h6" mb={1}>
            Text
          </Typography>
          <TextField
            fullWidth
            size="small"
            value={data?.text}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={styles.container}>
        <Grid item xs>
          <Typography variant="h6" mb={1}>
            Created
          </Typography>
          <TextField
            fullWidth
            size="small"
            value={data?.createdAt}
            disabled
          />
        </Grid>
        <Grid item xs>
          <Typography variant="h6" mb={1}>
            Updated
          </Typography>
          <TextField
            fullWidth
            size="small"
            value={data?.updatedAt}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={styles.container}>
        <Grid item xs>
          <Typography variant="h6" mb={1}>
            Stack
          </Typography>
          {isStackJson ? (
            <ReactJson src={stack} />
          ) : (
            <TextField
            fullWidth
            multiline
            size="small"
            value={stack}
            disabled
          />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
