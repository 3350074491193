import { Delete, Visibility } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import {format} from "date-fns";
import React, {useMemo} from 'react';

import { useTableItem } from '@/hooks';
import { openDeleteLogModal } from '@/redux/logs/slice';
import { ILogResponse } from "@/types/responses/logs";

const LogTableItem: React.FC<ILogResponse> = (log) => {
    const { id, type, text, createdAt } = log;

    const createdDate = useMemo(() => format(new Date(createdAt), 'dd.MM.yyyy k:m:s'), [createdAt]);

    const { onView, onDelete } = useTableItem(openDeleteLogModal);

    return (
        <TableRow>
            <TableCell component="th" scope="row" width={200}>
                {id}
            </TableCell>
            <TableCell component="th" scope="row" width={200}>
                {type}
            </TableCell>
            <TableCell component="th" scope="row" width={200}>
                {text}
            </TableCell>
            <TableCell component="th" scope="row">
                {createdDate}
            </TableCell>
            <TableCell width={100}>
                <IconButton title="View log" onClick={onView(id)}>
                    <Visibility />
                </IconButton>
                <IconButton title="Delete log" onClick={onDelete(log)}>
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default LogTableItem;
