import { Box, FormHelperText, Typography, FormControl } from '@mui/material';
import React from 'react';
import { useDropzone } from 'react-dropzone';

import { FilePreview } from '@/components';
import { DropZoneIcon } from '@/components/icons';

import styles from './styles';

import type { IFilesDropZoneInputProps } from './types';

function FilesDropZoneInput<FormValues extends Record<string, unknown> = Record<string, unknown>>({
  field: { value, name, onBlur },
  form: { touched, errors, setFieldValue },
  placeholder,
  downloadedImages,
  downloadedName,
  ...props
}: IFilesDropZoneInputProps<FormValues>) {
  const hasError = Boolean(touched[name] && errors[name]);

  const onDrop = (files: File[]) => {
    if (files.length) {
      setFieldValue(name, [...value, ...files]);
    }

  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ ...props, onDrop });

  const onRemove = (index: number) => () => {
    setFieldValue(
      name,
      value.filter((_, idx) => index != idx),
    );
  };

  const onRemoveDownloaded = (index: number) => () => {
    setFieldValue(
      downloadedName,
      downloadedImages?.filter((_, idx) => index != idx),
    );
  };

  return (
    <FormControl fullWidth>
      <Typography variant="h6" mb={1}>
        {placeholder}
      </Typography>
      <Box {...getRootProps()} sx={[styles.zone, isDragActive ? styles.active : null]}>
        <input {...getInputProps()} onBlur={onBlur} />
        <DropZoneIcon sx={styles.icon} />
        <Typography variant="h5">Add Images</Typography>
        <Typography variant="caption" sx={styles.caption}>
          {isDragActive
            ? 'Drop the files here...'
            : 'Upload files from your computer or drag files here.'}
        </Typography>
      </Box>
      <Box sx={styles.previewContainer}>
        {downloadedImages?.map((item, index) => (
          <FilePreview
            key={index}
            src={item}
            sx={styles.preview}
            onRemove={onRemoveDownloaded(index)}
            removeButtonProps={{ sx: styles.removeButton }}
          />
        ))}
        {Array.isArray(value) && value?.map((file, idx) => file instanceof Blob ? (
          <FilePreview
            key={idx}
            file={file as File}
            sx={styles.preview}
            onRemove={onRemove(idx)}
            removeButtonProps={{ sx: styles.removeButton }}
          />
        ) : null)}
      </Box>
      <FormHelperText error={hasError}>{(hasError ? errors[name] : ' ') as string}</FormHelperText>
    </FormControl>
  );
}

export default FilesDropZoneInput;
