import { KeyboardArrowDown } from '@mui/icons-material';
import { FormControl, Select, FormHelperText, Typography } from '@mui/material';
import React from 'react';

import type { ISelectInputProps } from './types';

const SelectInput = ({
  children,
  form: { touched, errors },
  field: { name, value, onChange, onBlur },
  ...props
}: ISelectInputProps) => {
  const hasError = Boolean(touched[name] && errors[name]);

  return (
    <FormControl error={hasError} fullWidth>
      <Typography variant="h6" mb={1}>
        {props.placeholder}
      </Typography>
      <Select
        displayEmpty={true}
        size="small"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        IconComponent={KeyboardArrowDown}
        {...props}
      >
        {children}
      </Select>
      <FormHelperText>{hasError && errors[name]}</FormHelperText>
    </FormControl>
  );
};

export default SelectInput;
