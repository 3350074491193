import { useParams } from 'react-router-dom';

import { useGetLogByIdQuery } from '@/apis/logs';

export const useViewLogPage = () => {
  const { id } = useParams();

  const { data: log = [], isLoading } = useGetLogByIdQuery(Number(id));

  const data = log[0] ?? {};

  return { data, isLoading }
}
