import { ThemeProvider } from '@mui/material';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider } from '@/components';
import { theme } from '@/constants';
import store from '@/redux/store';
import AppRouter from '@/router';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement as Element);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
    <ToastContainer />
  </Provider>,
);
