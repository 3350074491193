import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteLogByIdMutation } from '@/apis/logs';
import { useToastMessageRequest } from '@/hooks';
import { logsSelectors } from '@/redux/logs';
import { closeDeleteLogModal } from '@/redux/logs/slice';

export const useDeleteLogModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(logsSelectors.isDeleteLogModalOpenSelector);
  const log = useSelector(logsSelectors.selectedLogSelector)

  const [deleteLog, { isSuccess, ...requestParams }] = useDeleteLogByIdMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeleteLogModal());
  };

  const confirmDeleting = () => {
    deleteLog(log?.id);
  }

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return {
    isOpen, log, confirmDeleting, closeModal
  }
}
