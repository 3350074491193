import { SvgIcon } from '@mui/material';
import React from 'react';

import styles from '@/components/icons/styles';
import mergeSx from '@/utils/merge-sx';

import type { SvgIconProps } from '@mui/material';

const DropZoneIcon = ({ viewBox = '24 12.5 40 40', sx, ...rest }: SvgIconProps) => (
  <SvgIcon viewBox={viewBox} sx={mergeSx(styles.root, sx)} {...rest}>
    <circle cx="44" cy="32.5" r="20" fill="currentColor" />
    <path
      d="M48.714 40.2996H39.588C38.6892 40.3007 37.8267 39.9441 37.1911 39.3085C36.5555 38.6729 36.1989 37.8105 36.2001 36.9116V27.7856C36.2001 27.5713 36.0857 27.3732 35.9 27.2659C35.7144 27.1588 35.4856 27.1588 35.2999 27.2659C35.1144 27.3732 35 27.5713 35 27.7856V36.9116C35.0032 38.1274 35.4876 39.2926 36.3472 40.1524C37.207 41.012 38.3722 41.4964 39.588 41.4996H48.714C48.9283 41.4996 49.1264 41.3852 49.2337 41.1997C49.3409 41.014 49.3409 40.7852 49.2337 40.5997C49.1264 40.414 48.9283 40.2996 48.714 40.2996L48.714 40.2996Z"
      fill="white"
    />
    <path
      d="M50.5823 23.5H39.9702C39.3295 23.5015 38.7153 23.7568 38.2622 24.21C37.8091 24.6631 37.5538 25.2772 37.5522 25.918V36.5301C37.5538 37.1708 37.8091 37.7849 38.2622 38.238C38.7153 38.6912 39.3295 38.9465 39.9702 38.948H50.5823C51.223 38.9465 51.8372 38.6912 52.2903 38.238C52.7434 37.7849 52.9987 37.1708 53.0003 36.5301V25.918C52.9987 25.2772 52.7434 24.6631 52.2903 24.21C51.8372 23.7569 51.223 23.5015 50.5823 23.5ZM49.2824 31.8278H45.8824V35.2278H45.8823C45.8823 35.4422 45.7679 35.6403 45.5823 35.7475C45.3967 35.8546 45.168 35.8546 44.9823 35.7475C44.7966 35.6403 44.6824 35.4422 44.6824 35.2278V31.8278H41.2823C41.068 31.8278 40.8699 31.7134 40.7627 31.5279C40.6556 31.3422 40.6556 31.1135 40.7627 30.9278C40.8699 30.7422 41.068 30.6279 41.2823 30.6279H44.6822V27.228L44.6824 27.2278C44.6824 27.0135 44.7966 26.8154 44.9823 26.7083C45.168 26.601 45.3967 26.601 45.5823 26.7083C45.7679 26.8154 45.8823 27.0135 45.8823 27.2278V30.6278H49.2822V30.6279C49.4967 30.6279 49.6948 30.7422 49.8019 30.9278C49.9091 31.1135 49.9091 31.3422 49.8019 31.5279C49.6948 31.7134 49.4967 31.8278 49.2822 31.8278H49.2824Z"
      fill="white"
    />
  </SvgIcon>
);

export default DropZoneIcon;
