import { createSelector } from '@reduxjs/toolkit';

import type { ILogsState } from './types';
import type { RootState } from '@/redux/store';

export const logsStateSelector = (state: RootState) => state.logs;

export const selectedLogSelector = createSelector(
  logsStateSelector,
  (state: ILogsState) => state.selectedLog,
);

export const isDeleteLogModalOpenSelector = createSelector(
  logsStateSelector,
  (state: ILogsState) => state.isDeleteLogModalOpen,
);

export const isDeleteAllLogsModalOpenSelector = createSelector(
  logsStateSelector,
  (state: ILogsState) => state.isDeleteAllLogsModalOpen,
);
