import { createSlice } from '@reduxjs/toolkit';

import { ILogsState } from '@/redux/logs/types';

const initialState: ILogsState = {
  isDeleteLogModalOpen: false,
  isDeleteAllLogsModalOpen: false,
  selectedLog: null,
};

export const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    openDeleteLogModal(state: ILogsState, { payload: log }) {
      state.isDeleteLogModalOpen = true;
      state.selectedLog = log;
    },
    closeDeleteLogModal(state: ILogsState) {
      state.isDeleteLogModalOpen = false;
      state.selectedLog = null;
    },
    openDeleteAllLogsModal(state: ILogsState) {
      state.isDeleteAllLogsModalOpen = true;
    },
    closeDeleteAllLogsModal(state: ILogsState) {
      state.isDeleteAllLogsModalOpen = false;
    },
  },
});

export const {
  openDeleteLogModal,
  closeDeleteLogModal,
  openDeleteAllLogsModal,
  closeDeleteAllLogsModal,
} = logsSlice.actions;

export default logsSlice.reducer;
