export const getTomorrowDate = () => new Date(new Date().getTime() + 60 * 60 * 24 * 1000);

export const getNextWeekDate = () => new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);

export const addMonths = (numOfMonths: number, date = new Date()) => {
  date.setMonth(date.getMonth() + numOfMonths);

  return date;
};

export const getNextMonthDate = () => addMonths(1);

export const getPreviousDate = (date: Date = new Date()) => {
  const d = new Date(date);
  return new Date(d.setDate(d.getDate() - 1));
};

export const getNextDate = (date: Date = new Date()) => {
  const d = new Date(date);
  return new Date(d.setDate(d.getDate() + 1));
};
