export const styles = {
  container: {
    display: 'flex',
    flexDirection: {
      md: 'row',
      xs: 'column-reverse',
    },
  },
  btnWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dateBtn: {
    mr: 2,
    mt: {
      md: 0,
      xs: 1,
    },
  },
  btn: {
    width: 120,
  },
  dateButtons: {
    display: 'flex',
    flexDirection: {
      md: 'row',
      sm: 'column',
    },
  },
} as const;
