import { Cancel } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';

import useFilePreview from './useFilePreview';

import type { IFilePreviewProps } from './types';
import type { BoxProps } from '@mui/system';

function FilePreview<TComponent extends 'a' | 'img'>({
  src,
  file,
  onRemove,
  removeButtonProps,
  ...props
}: IFilePreviewProps<TComponent>) {
  const { imgSrc } = useFilePreview(src, file);

  return (
    <>
      <Tooltip title={src || ''}>
        <Box {...(props as BoxProps<'img'>)} component="img" src={imgSrc} />
      </Tooltip>
      {!!onRemove && (
        <IconButton {...removeButtonProps} aria-label="delete" onClick={onRemove}>
          <Cancel />
        </IconButton>
      )}
    </>
  );
}

export default FilePreview;
