import React, { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { AuthGate } from '@/components';
import { Pathes } from '@/constants';
import { SignInPage } from '@/views';

import routes from './routes';

const AppRouter: FC = () => {
  return (
    <Routes>
      <Route path={Pathes.Main} element={<Navigate to={Pathes.Users} />} />
      <Route path={Pathes.SignIn} element={<SignInPage />} />
      {routes.map(({ path, layout: Layout, component: Component, ...layoutProps }) => (
        <Route
          element={
            <AuthGate>
              <Layout {...layoutProps}>
                <Component />
              </Layout>
            </AuthGate>
          }
          path={path}
          key={path}
        />
      ))}
    </Routes>
  );
};

export default AppRouter;
