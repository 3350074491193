export function objToFormData<T>(params: T): FormData {
  const form_data = new FormData();

  for (const key in params) {
    if (Array.isArray(params[key]) && (params[key] as Blob[])[0] instanceof Blob) {
      for (let i = 0; i < (params[key] as Blob[]).length; i++) {
        form_data.append(key, (params[key] as Blob[])[i]);

      }
      continue;
    }
    form_data.append(key, params[key] as string | Blob);
  }
  return form_data;
}
