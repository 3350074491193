import { Container, Typography } from '@mui/material';
import React from 'react';

import { UserForm } from '@/components';

import { useCreateUserPage } from './useCreateUserPage';

const CreateUserPage = () => {
  const props = useCreateUserPage();
  return (
    <Container>
      <Typography variant="h4" my={2}>
        User form
      </Typography>
      <UserForm {...props} />
    </Container>
  );
};

export default CreateUserPage;
