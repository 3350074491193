import { Box } from '@mui/material';
import React from 'react';

import { EmailSignInForm } from '@/components';

import { styles } from './styles';

const SignInPage = () => (
  <Box sx={styles.container}>
    <Box sx={styles.form}>
      <EmailSignInForm />
    </Box>
  </Box>
);

export default SignInPage;
