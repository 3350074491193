export const styles = {
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: "url('/assets/background.jpeg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  form: {
    width: {
      lg: '40%',
      md: '70%',
      xs: '90%',
    },
  },
} as const;
