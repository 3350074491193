import { Delete } from '@mui/icons-material';
import { Box, Button, TextField, Typography } from '@mui/material';
import React from 'react';

import { CustomTable } from "@/components";
import { DeleteAllLogsModal } from '@/components/modals/DeleteAllLogsModal';
import { DeleteLogModal } from '@/components/modals/DeleteLogModal';
import { useMainLogsPage } from "@/views/logs/MainLogsPage/useMainLogsPage";

import LogTableItem from "../../../components/table-items/LogTableItem/LogTableItem";

import { headCells } from './constants';

const MainLogsPage: React.FC = () => {
    const { logs, onOpenDeleteAllLogsModal, onSearchChange, ...tableProps } = useMainLogsPage();

    return (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              placeholder="Search (type or text)"
              size="small"
              onChange={onSearchChange}
            />
            <Button variant="contained" onClick={onOpenDeleteAllLogsModal}>
              <Delete />
              <Typography ml={1} fontWeight="bold" >
                Remove all logs
              </Typography>
            </Button>
          </Box>
            <CustomTable headCells={headCells} {...tableProps} deletingModal={DeleteLogModal}>
                {logs?.items.map((log) => (
                    <LogTableItem key={log.id} {...log} />
                ))}
            </CustomTable>
          <DeleteAllLogsModal />
        </>
    );
};

export default MainLogsPage;
