import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { ILogResponse } from "@/types/responses/logs";
import { envUtil } from '@/utils';

import type { IRequestParams, IResponse } from '@/types';

const { api } = envUtil.getEnv();

export const logsApi = createApi({
    reducerPath: 'logsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: api,
        prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
    }),
    tagTypes: ['Logs', 'Log'],
    endpoints: (builder) => ({
        getLogs: builder.query<IResponse<ILogResponse>, IRequestParams & { search?: string }>({
            query: (params) => ({
                url: ApiRoutes.Logs,
                params,
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.items.map(({ id }) => ({
                            type: 'Logs' as const,
                            id,
                        })),
                        { type: 'Logs', id: 'LIST' },
                    ]
                    : [{ type: 'Logs', id: 'LIST' }],
            keepUnusedDataFor: 1,
        }),
        getLogById: builder.query<ILogResponse[], number>({
            query: (id) => ({
                url: `${ApiRoutes.Logs}/${id}`,
            }),
            providesTags: () => ['Log'],
        }),
        deleteLogs: builder.mutation<void, void>({
            query: () => ({
                url: ApiRoutes.Logs,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Logs', id: 'LIST' }],
        }),
        deleteLogById: builder.mutation<void, number | undefined>({
            query: (id) => ({
                url: `${ApiRoutes.Logs}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Logs', id: 'LIST' }],
        }),
    }),
});

export const {
    useGetLogsQuery,
    useGetLogByIdQuery,
    useDeleteLogsMutation,
    useDeleteLogByIdMutation,
} = logsApi;
