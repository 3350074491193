const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    mt: 6,
  },
  signInBtn: {
    mt: 4,
  },
  navLink: {
    mt: 1,
    alignSelf: 'center',
  },
  strong: {
    ml: 0.5,
    fontWeight: 700,
    display: 'inline-block',
  },
} as const;

export default styles;
